div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.tutorialContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    z-index: 0;

    .text {
        text-align: center;
        font-size: 6vh;
        font-family: "Helenita";
        color: #FECD57;
        transform: scale(0);
        transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        margin-bottom: 5vh;
        margin-top: 5vh;
        white-space: pre-wrap;
        text-shadow: 0.25vh 0.5vh black;

        &.show {
            transform: scale(1);
            transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &.larger {
            font-size: 15vh;
        }
    }

    .voteCount {
        position: absolute;
        margin: auto;
        top: 2vh;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 5vh;
        font-family: "Helenita";
        color: #FECD57;
        text-shadow: 0.25vh 0.5vh black;
    }

    .step {
        display: flex;
        flex-direction: column;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        width: fit-content;
        height: fit-content;

        &.show {
            opacity: 1;
        }
    }

    .stepOne {
        .playerRow {
            display: flex;
            width: auto;
            height: auto;
            justify-content: center;
            align-items: center;

            .player {
                width: 12.5vw;
                transform: scale(0) translateX(0vw);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
                position: relative;

                &.show {
                    transform: scale(1) translateX(0vw);
                }

                &.move {
                    transform: scale(1) translateX(-6.25vw);
                }

                .dave {
                    width: 12.5vw;
                    position: relative;
                    transform: scale(1.4);
                }

                .daveLabel {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 5%;
                    bottom: 0;
                    right: -100%;
                }
            }
        }
    }

    .stepTwo {
        .rowBox {
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 75vh;
            margin-top: 5%;
            /*gap: 10vh;*/

            .daveSection {
                position: relative;
                display: flex;
                width: 37.5%;
                height: 70%;

                .dave {
                    width: 50%;
                    height: auto;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    transform: scale(0);
                    transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                    &.show {
                        transform: scale(1);
                    }
                }

                .bubble {
                    width: 90%;
                    height: auto;
                    position: absolute;
                    margin: auto;
                    right: 0;
                    left: 0;
                    bottom: 35%;
                }
            }

            .phoneSection {
                display: flex;
                width: 25%;
                height: 100%;

                .phone {
                    width: 100%;
                    height: auto;
                    transform: translateY(80vh);
                    transition: transform 0.75s cubic-bezier(0.34, 1.56, 0.64, 1);
                    background-image: url("../images/Tutorial/Tutorial_Dave Screen.png");
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    padding: 25%;
                    padding-bottom: 60%;

                    &.show {
                        transform: translateY(0);
                    }

                    .drawAnim {
                    }
                }
            }

            .textSection {
                display: flex;
                width: 37.5%;
                height: 70%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-right: 2.5%;
            }
        }
    }

    .stepThree {
        .playerRow {
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
            align-items: center;

            .playerBox {
                display: flex;
                width: 20vw;
                margin-left: 3vw;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                &.show {
                    transform: scale(1);
                }

                .avatar {
                    width: 50%;
                    height: auto;
                }

                .phone {
                    width: 50%;
                    height: auto;
                }
            }
        }
    }

    .stepFour {
        .stageSection {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: all 2s;
            margin: auto;
            bottom: -150vh;
            z-index: 11;

            &.show {
                bottom: -30vh;
            }
        }
    }
}
