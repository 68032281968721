div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;
    width: fit-content;
    transition: 1s;

    &.topLeft {
        transform: scale(0.4);
        top: -80vh;
        left: -75vw;
    }

    .logo {
        height: 35vh;
        margin-right: 1vh;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #0F3B8B;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    background-image: url(../images/Host/BG.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 0;

    .testRow {
        position: absolute;
        margin: auto;
        z-index: 50;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;

        .testItem {
            position: relative;
            width: auto;
            height: 40vh;
            margin: 2vh;
            /*  -webkit-mask: url(../images/White-Blob-Test.gif) no-repeat center/99%;
            mask: url(../images/White-Blob-Test.gif) no-repeat center/99%;*/
            /*       margin: auto;
            left: 0;
            right: 0;*/
            /*background: #65BFCE;*/
        }
    }


    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;
        z-index: 20;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Resistenza";
                text-shadow: 0.25vh 0.5vh black;
                font-weight: bold;
                color: #FECD57;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: "Resistenza";
                    text-shadow: 0.25vh 0.5vh black;
                    font-size: 4vh;
                    font-weight: normal;
                    color: white;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .timerSection {
        position: absolute;
        width: 30vh;
        height: 12.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 0.5vh black;
        background-color: #072C76;
        border-radius: 4vh;
        margin: auto;
        left: 0;
        right: 0;
        top: 2vh;
        padding: 1vh;
        transition: 1s transform;
        transform: translateY(-150%);
        z-index: 15;

        &.show {
            transform: translateY(0%);
        }

        .timerText {
            text-align: center;
            font-size: 15vh;
            font-family: "Remachine";
            color: white;
            width: fit-content;
            height: fit-content;
            line-height: 1;
            position: absolute;
            left: 0;
            right: 35%;
            bottom: 0;
            margin: auto;
            top: -25%;
        }

        .clock {
            height: 17vh;
            position: absolute;
            left: 0;
            right: 0;
            transform: translate(22%, -8%);
            margin: auto;
        }
    }


    .mainButton {
        position: relative;
        font-size: 5vh;
        font-family: "Resistenza";
        padding-bottom: 4vh;
        padding-top: 3vh;
        padding-left: 6vh;
        padding-right: 6vh;
        margin: 1vh;
        line-height: 1;
        background-color: transparent;
        color: black;
        border: none;
        text-transform: uppercase;
        z-index: 1;

        &.larger {
            font-size: 8vh;
        }

        .buttonImg {
            position: absolute;
            z-index: -1;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;

            &.flipped {
                transform: scaleX(-1);
            }
        }
    }

    .startButtonSection {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 11;
        height: fit-content;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            /*margin-top: -4vh;*/

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #FECD57;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 2vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #DA7C0F !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: black;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 2vh;
                font-size: 5.5vh;
                border-radius: 2vh;
                font-family: "Helenita";
                color: #FECD57;
                text-shadow: 0.25vh 0.5vh black;
                margin-bottom: 2vh;
                margin: auto;
            }
        }
    }

    .playAgainButtonSection {
        width: 50%;
        height: 25%;
        margin: auto;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 9;

        .button {
            position: relative;
            font-size: 5vh;
            font-family: "Resistenza";
            padding-bottom: 4vh;
            padding-top: 3vh;
            padding-left: 6vh;
            padding-right: 6vh;
            margin: 1vh;
            line-height: 1;
            /*            background-image: url('../images/Host/ButtonImg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-color: unset;*/
            background-color: transparent;
            color: black;
            border: none;
            text-transform: uppercase;
            z-index: 1;

            .buttonImg {
                position: absolute;
                z-index: -1;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;

                &.flipped {
                    transform: scaleX(-1);
                }
            }
        }
    }

    .playerColumn {
        position: absolute;
        width: 20%;
        height: 75%;
        margin: auto;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 1s;


        &.left {
            align-items: start;
            left: -30%;

            &.show {
                left: 0;
            }
        }

        &.right {
            right: -30%;
            align-items: end;

            &.show {
                right: 0;
            }
        }
    }

    .curtains {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        pointer-events: none;


        .curtain {
            position: absolute;
            width: 52%;
            height: 100%;
            transition: all 1s;

            &.left {
                left: -52%;
                transform: translateX(-2%);

                &.closed {
                    left: 0;
                }
            }

            &.right {
                right: -52%;
                transform: translateX(2%);

                &.closed {
                    right: 0;
                }
            }
        }

        .titleText {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            font-size: 30vh;
            font-family: "Remachine";
            color: #FECD57;
            text-shadow: 5px 10px black;
            width: fit-content;
            height: fit-content;
            opacity: 0;
            transition: all 1s;
            text-align: center;
            line-height: 1;

            &.show {
                opacity: 1;
            }
        }
    }

    .daveSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        .easelSection {
            position: absolute;
            height: fit-content;
            width: fit-content;
            display: flex;
            margin: auto;
            left: 53vh;
            top: 10vh;
            z-index: 2;
            right: 0;

            &.front {
                z-index: 1;
            }

            .daveEasel {
                position: relative;
                height: 110vh;
                width: auto;
                margin: auto;
            }

            .daveAnswer {
                position: absolute;
                font-family: "KCLisaHand";
                text-align: center;
                height: -moz-fit-content;
                height: fit-content;
                width: -moz-fit-content;
                width: fit-content;
                margin: auto;
                top: 0;
                left: 0;
                right: 0;
                bottom: 62vh;
                z-index: 2;
                line-height: 1;
                color: black;
            }

            .daveDrawing {
                position: absolute;
                margin: auto;
                width: 90%;
                height: auto;
                left: 0;
                right: 0;
                bottom: 17vh;
                top: 0;
                z-index: 1;
            }
        }



        .daveStool {
            position: absolute;
            margin: auto;
            left: 0;
            right: 30vh;
            height: 35vh;
            bottom: -15vh;
        }

        .davePotato {
            position: absolute;
            margin: auto;
            left: 0;
            right: 30vh;
            height: 65vh;
            width: fit-content;
            bottom: 4vh;
            z-index: 1;
        }

        .daveSubText {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 1vh;
            font-size: 20vh;
            font-family: "Remachine";
            color: #FECD57;
            text-shadow: 4px 8px black;
            width: fit-content;
            height: fit-content;
            z-index: 3;
            line-height: 1;
            text-align: center;

            .smaller {
                font-size: 15vh;
            }
        }
    }

    .stageSection {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 2s;
        margin: auto;
        bottom: -150vh;
        z-index: 11;

        &.show {
            bottom: -10vh;
        }

        .stageTitle {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 13vh;
            font-size: 17.5vh;
            font-family: "Remachine";
            color: #FECD57;
            text-shadow: 4px 8px black;
            width: fit-content;
            height: fit-content;
            z-index: 10;
            line-height: 1;
            text-align: center;
        }
    }

    .winnerSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: end;
        /*transition: 2s all;*/
        bottom: -100vh;
        opacity: 0;
        z-index: 9;

        &.show {
            bottom: 0;
            opacity: 1;
        }

        .stageHalf {
            position: absolute;
            width: 200%;
            height: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            left: 0;
            transition: linear;

            &.animate2 {
                animation-name: animation2;
                animation-duration: 4s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            &.animate3 {
                animation-name: animation3;
                animation-duration: 6s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            &.animate4 {
                animation-name: animation4;
                animation-duration: 8s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            &.animate5 {
                animation-name: animation5;
                animation-duration: 10s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            &.animate6 {
                animation-name: animation6;
                animation-duration: 12s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            &.animate7 {
                animation-name: animation7;
                animation-duration: 14s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            &.animate8 {
                animation-name: animation8;
                animation-duration: 16s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            @keyframes animation2 {
                from {
                    left: 0vh;
                }

                to {
                    left: 0vw;
                }
            }

            @keyframes animation3 {
                from {
                    left: 0vh;
                }

                to {
                    left: -25vw;
                }
            }

            @keyframes animation4 {
                from {
                    left: 0vh;
                }

                to {
                    left: -50vw;
                }
            }

            @keyframes animation5 {
                from {
                    left: 0vh;
                }

                to {
                    left: -75vw;
                }
            }

            @keyframes animation6 {
                from {
                    left: 0vh;
                }

                to {
                    left: -100vw;
                }
            }

            @keyframes animation7 {
                from {
                    left: 0vh;
                }

                to {
                    left: -125vw;
                }
            }

            @keyframes animation8 {
                from {
                    left: 0vh;
                }

                to {
                    left: -150vw;
                }
            }

            .stage {
                width: 50%;
                height: 100%;
                position: relative;
                display: flex;

                .stageImg {
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    bottom: -20vh;
                    z-index: 1;
                }

                .winnerRow {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: start;
                    align-items: end;
                    margin-bottom: 20vh;

                    .winner {
                        position: relative;
                        width: 25%;
                        height: 50%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;

                        .easelSection {
                            position: absolute;
                            width: 27.5vh;
                            height: fit-content;
                            display: flex;
                            margin: auto;
                            left: 10vh;
                            right: 0;
                            top: -25vh;

                            .badge {
                                position: absolute;
                                margin: auto;
                                right: -2vh;
                                top: 30vh;
                                width: 10vh;
                            }

                            .drawingTitle {
                                position: absolute;
                                font-family: "KCLisaHand";
                                text-align: center;
                                height: -moz-fit-content;
                                height: fit-content;
                                width: -moz-fit-content;
                                width: fit-content;
                                margin: auto;
                                top: 0;
                                left: 0;
                                right: 0;
                                top: 13vh;
                                z-index: 2;
                                line-height: 1;
                                color: black;
                                font-size: 2.5vh;
                            }

                            .easelImage {
                                position: absolute;
                                width: 100%;
                                height: auto;
                            }

                            .drawingImage {
                                position: absolute;
                                width: 85%;
                                height: auto;
                                margin: auto;
                                top: 15vh;
                                left: 0;
                                right: 0;
                            }
                        }

                        .potato {
                            position: relative;
                            margin: auto;
                            margin-left: 1vh;
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            width: 30vh;
                            height: auto;
                        }

                        .name {
                            font-size: 5vh;
                            font-family: "Resistenza";
                            color: #FECD57;
                            text-shadow: 2px 4px black;
                            width: 27.5vh;
                            height: fit-content;
                            z-index: 2;
                            line-height: 1;
                            text-align: center;
                            margin: auto;
                            position: absolute;
                            top: 15vh;
                            right: -5vh;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    .leaderboardSection {
        position: absolute;
        width: 40%;
        height: fit-content;
        margin: auto;
        justify-content: start;
        align-items: start;
        display: flex;
        flex-direction: column;
        transition: 0.75s transform;
        transform: translateX(50vw);
        z-index: 10;
        top: 18%;
        right: 8%;

        &.show {
            transform: translateX(0vw);
        }

        .tableRow {
            position: relative;
            font-family: "Resistenza";
            font-size: 4vh;
            display: flex;
            flex-direction: row;
            width: 90%;
            align-items: center;
            padding-right: 10%;
            /*padding-left: 2%;*/
            background-color: white;
            border: black 0.75vh solid;
            border-radius: 3px;

            &.header {
                background-color: transparent;
                border: none;
                border-radius: unset;
                color: #F9CE63;

                .position {
                    background-color: transparent !important;
                    font-family: "Resistenza" !important;
                    font-size: 4vh !important;
                }
            }
        }

        .player {
            border-radius: 1.5vh;
            margin-top: 2%;


            div {
                font-family: "Resistenza";
                font-size: 4vh;
                border: none;
            }
        }

        .position {
            text-align: start;
            width: 10%;
            background-color: black;
            text-align: center;
            color: #F9CE63;
        }

        .name {
            width: 70%;
            text-align: center;
        }

        .score {
            width: 20%;
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;
        }

        .potato {
            position: absolute;
            width: auto;
            height: 9.5vh;
            margin: auto;
            right: 0;
            bottom: 0;
            top: 0;
        }
    }
}
