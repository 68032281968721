
@font-face {
    font-family: "KCLisaHand";
    src: url('./fonts/KCLisaHand.otf') format('opentype');
}
@font-face {
    font-family: "Remachine";
    src: url('./fonts/RemachineScript.ttf') format('truetype');
}
@font-face {
    font-family: "Resistenza";
    src: url('./fonts/Resistenza-PeperoncinoSans.otf') format('opentype');
}
@font-face {
    font-family: "Helenita";
    src: url('./fonts/HelenitaBook.otf') format('opentype');
}
@font-face {
    font-family: "JenWagner";
    src: url('./fonts/JenWagnerCo.-PalmerLakePrintRegular.otf') format('opentype');
}
.exampleFont {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}