[contenteditable] {
    outline: 0px solid transparent;
}


:global(div) {
    touch-action: manipulation;
}

:global(img) {
    touch-action: manipulation;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 600px !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #0D408C;
    background-image: url(../images/mobileBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .connectionIssueContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        z-index: 10000;
        justify-content: center;
        align-items: center;

        .connectionText {
            width: fit-content;
            height: fit-content;
            font-size: 30px;
            color: white;
            font-family: "Resistenza";
            text-align: center;
        }

        .refreshButton {
            font-family: 'Lucida Sans Unicode';
            padding: 10px;
            line-height: 1;
            font-weight: bolder;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            width: fit-content;
            height: fit-content;
            font-size: 75px;
            color: #1B1464;
            border-radius: 20px;
            background-color: #FECD57;
            box-shadow: #DA7C0F 0vh 1.5vh;
            cursor: pointer;
        }
    }

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
            font-family: "Resistenza";
            text-shadow: -0.75vh 0.75vh 0px black;
        }
    }

    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        background-color: #FECD57;
        box-shadow: #DA7C0F 0vh 1.5vh;

        .skipButton {
            font-size: 4vh;
            color: black;
            text-align: center;
            padding: 1vh;
            margin: auto;
            position: relative;
            font-family: "Resistenza";
        }
    }

    .genericTitle {
        width: 90%;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 7vh;
        color: white;
        line-height: 1;
        font-family: "Resistenza";
        text-shadow: -0.75vh 0.75vh 0px black;

        &.yellow {
            color: #FECD57;
            font-size: 8vh;
        }

        &.fancy {
            color: #FECD57;
            font-size: 6vh;
            font-family: "Remachine";
            text-shadow: -0.5vh 0.5vh 0px black;
        }

        &.smaller {
            font-size: 5vh;
        }
    }

    .drawingSection {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: auto;
        margin-bottom: auto;

        .drawingContainer {
            /*margin-top: 6vh;*/
            height: fit-content;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;

            .questionText {
                position: relative;
                width: 100%;
                height: fit-content;
                line-height: 1;
                margin: auto;
                margin-bottom: unset;
                text-align: center;
                font-size: 5.5vh;
                color: #FECD57;
                font-family: "Remachine";
                z-index: 1;
            }

            .drawCanvas {
                position: relative;
                margin: auto;
                margin-top: unset;
                z-index: 1;
                border: 0.25vh dashed black !important;
            }

            .easelBg {
                position: absolute;
                width: 90%;
                margin: auto;
                left: 0;
                right: 0;
                top: 50%;
                bottom: 0;
            }
        }


        .toolsContainer {
            margin: auto;
            margin-top: 20px;
            margin-bottom: 3%;
            border-radius: 10px;
            background-color: white;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 3.5px;
            padding-bottom: 3.5px;
            z-index: 1;
            box-shadow: inset 4px 4px 10px 0px rgba(0,0,0,0.25);
            height: fit-content;
            width: 350px;
            max-width: 90%;

            .toolsInner {
                width: 100%;
                height: fit-content;
                margin: auto;
                overflow-x: auto;
                overflow-y: hidden;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;

                &::-webkit-scrollbar {
                    height: 10px;
                    transform: scale(2);
                }

                &::-webkit-scrollbar-track {
                    background-color: white;
                    border-radius: 20px;
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                    box-shadow: none;
                    background-color: #E90F79;
                    border-radius: 20px;
                }

                .chosenPen {
                    width: 35px !important;
                    height: 35px !important;
                    min-width: 35px;
                    min-height: 35px;
                    margin: auto;
                    display: flex;
                    border-radius: 50%;
                    /*margin-left: 1vh;*/
                    border: 2px solid #E61C43;
                    /*margin-right: 1vh;*/
                    position: relative;
                    /*user-select: initial !important;*/
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    margin-left: 2px;
                    margin-right: 2px;
                    margin-bottom: 3.5px;
                    margin-top: 3.5px;

                    .innerPen {
                        background-color: black;
                        border-radius: 50%;
                        /*margin: auto;*/
                    }
                }

                .tool {
                    width: 35px;
                    height: 35px;
                    margin: auto;
                    /*                margin-left: 0.25vh;
                margin-right: 0.25vh;*/
                    /*user-select: initial !important;*/
                    cursor: pointer;
                    margin-left: 2px;
                    margin-right: 2px;
                    margin-bottom: 3.5px;
                    margin-top: 3.5px;

                    &.toggled {
                        border-radius: 5px;
                        background-color: #E61C43;
                    }

                    &.flipped {
                        transform: scaleX(-1);
                    }
                }
            }


            .penSizeSection {
                width: fit-content;
                height: fit-content;
                padding: 1vh;
                margin: auto;
                flex-direction: row;
                position: absolute;
                left: 0;
                right: 0;
                top: 20vh;
                bottom: 0;
                border: solid 0.45vh grey;
                border-radius: 1vh;
                background: white;
                display: none;

                &.show {
                    display: flex;
                }

                .penContainer {
                    display: flex;
                    margin: auto;
                    height: 4vh;
                    width: 4vh;
                    /*user-select: initial !important;*/
                    cursor: pointer;

                    &.show {
                        border: 0.25vh solid #E61C43;
                        border-radius: 50%;
                    }

                    .penSize {
                        margin: auto;
                        border-radius: 50%;
                        display: flex;
                        background-color: black;

                        .innerCircle {
                            width: 50%;
                            height: 50%;
                            margin: auto;
                            background-color: white;
                            border-radius: 50%;
                            display: none;

                            &.show {
                                display: initial;
                            }
                        }
                    }
                }
            }
        }
    }


    .guessSection {
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .guessDrawing {
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            width: 90%;
            max-width: 500px;
            height: auto;
            border: 0.4vh solid black;
            border-radius: 1vh;
        }

        .guessInput {
            width: 75%;
            margin: auto;
            padding: 2%;
            max-width: 500px;
            border: 0.4vh solid black;
            text-align: center;
            font-size: 3.5vh;
            font-family: "Resistenza";

            &::placeholder {
                color: rgba(128, 128, 128, 0.5);
            }
        }

        .guessButtonsBox {
            width: 100%;
            height: 30%;
            margin-bottom: 0;
            margin-top: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin-bottom: 5%;
            justify-content: space-between;
        }
    }

    .choosingSection {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .choiceList {
            margin: auto;
            width: 95%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            .choice {
                width: 47%;
                height: fit-content;
                background-color: white;
                border: solid 0.4vh black;
                font-size: 3vh;
                font-family: "KCLisaHand";
                margin: 1%;
                text-align: center;
                line-height: 1;
                padding-top: 2%;
                padding-bottom: 2%;
                overflow-wrap: break-word;

                &.selected {
                    background-color: #FECD57;
                }
            }
        }
    }

    .submitButton {
        margin: auto;
        background-color: #FECD57;
        box-shadow: #DA7C0F 0vh 1.5vh;
        border-radius: 2vh;
        font-size: 10vh;
        font-family: "Remachine";
        color: #1B1464;
        line-height: 1;
        padding: 0.5vh;
        padding-left: 1.5vh;
        padding-right: 1.5vh;
        border: none;
        cursor: pointer;
        z-index: 2;

        &.smaller {
            font-size: 6vh;
        }

        &.clearCanvas {
            /*margin-top: 5vh;*/
        }
    }
    // Small devices (landscape phones, 576px and up) - anything smaller than this uses the default styles (above)
    /*@media (min-width: 481px) {
        .genericTitle {
            font-size: 55px;
        }
    }
    // Medium devices (tablets, 768px and up)
    @media (min-width: 700px) {
        .genericTitle {
            font-size: 60px;
        }
    }*/
}


