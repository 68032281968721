

.player {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    margin: 0.5vh;
    justify-content: start;
    align-items: center;

    .potato {
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15vh;
        width: auto;
    }

    .textSection {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        max-width: 50%;

        .name {
            font-family: "Resistenza";
            text-shadow: 0.2vh 0.4vh black;
            font-size: 2.75vh;
            color: white;

            &.large {
                font-size: 3.5vh;
            }

            &.medium {
                font-size: 2.75vh;
            }

            &.small {
                font-size: 2vh;
            }
        }

        .box {
            background-color: white;
            border: solid black 0.25vh;
            text-align: center;
            width: fit-content;
            font-size: 2.75vh;
            padding: 0.5vh;
            padding-top: 0.75vh;
            line-height: 1;
            font-family: "KCLisaHand";
            opacity: 0;
            max-width: 100%;
            overflow-wrap: break-word;

            &.show {
                opacity: 1;
            }
        }

        .points {
            color: #FECD57;
            font-size: 2.5vh;
            font-family: "Resistenza";
            text-shadow: 0.2vh 0.4vh black;
        }
    }

    &.reverse {
        flex-direction: row-reverse;
        justify-content: end;

        .textSection {
            .name {
                text-align: end;
            }

            .points {
                text-align: end;
            }
        }
    }
}
