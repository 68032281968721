
.stage {
    position: absolute;
    width: 75%;
    height: 50%;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;

    .stageImg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 8;
    }

    .stagePlayer {
        position: absolute;
        width: fit-content;
        height: fit-content;
        margin: auto;
        top: -30vh;
        transform: translateY(0);
        transition: transform 0.75s cubic-bezier(0.34, 1.56, 0.64, 1);

        &.hide {
            transform: translateY(50vh);
        }

        .easelSection {
            position: absolute;
            width: 22.5vh;
            height: -moz-fit-content;
            height: fit-content;
            display: flex;
            bottom: 4vh;

            .easelImage {
                position: absolute;
                width: 100%;
                height: auto;
            }

            .drawingImage {
                position: absolute;
                width: 85%;
                height: auto;
                margin: auto;
                top: 10vh;
                left: 0;
                right: 0;
            }
        }

        .potato {
            position: absolute;
            margin: auto;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 19vh;
            top: 22vh;
            left: -9vh;
        }

        .answerSection {
            position: absolute;
            width: 23vh;
            height: 10vh;
            display: flex;
            margin: auto;
            left: 5vh;
            right: 0;
            top: 20vh;
            justify-content: center;
            align-items: center;
            transform-origin: bottom left;
            transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
            transform: scale(0) rotate(90deg);

            &.show {
                opacity: 1;
                transform: scale(1) rotate(0deg);
            }

            .bubbleBox {
                position: absolute;
                top: -3vh;
                width: 100%;
                height: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;

                .fakeBubble {
                    position: relative;
                    width: 100%;
                    height: auto;
                    z-index: 1;
                }

                .bubbleImg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-image: url(../images/Host/Bubble.png);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    -webkit-mask: url(../images/Host/Bubble.png) no-repeat center/100%;
                    mask: url(../images/Host/Bubble.png) no-repeat center/100%;
                    background: white;

                    &.correct {
                        background: #429152;
                    }

                    &.wrong {
                        background: #E61D42;
                    }
                }
            }


            .answerText {
                position: relative;
                width: 100%;
                height: fit-content;
                z-index: 1;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                color: black;
                font-size: 3vh;
                font-family: "KCLisaHand";
                text-align: center;
                line-height: 1;
                transform: rotate(-2deg);
                width: 82%;
                overflow-wrap: break-word;
            }
        }

        &.position-1 {
            .easelSection {
                bottom: 6.5vh;
            }
        }

        &.position-2 {
        }

        &.position-3 {
            .easelSection {
                bottom: 2vh;

                .drawingImage {
                    right: 0.5vh;
                }
            }
        }

        &.position-4 {
            .easelSection {
                bottom: 0vh;

                .drawingImage {
                    right: 1vh;
                }
            }
        }

        &.position-5 {
        }

        &.position-6 {
            .easelSection {
                bottom: 2vh;

                .drawingImage {
                    left: 0.5vh;
                }
            }
        }

        &.position-7 {
            .easelSection {
                bottom: 0vh;

                .drawingImage {
                    left: 1vh;
                }
            }
        }
    }

    &.variation-1 {
        width: 60%;

        .stagePlayer {
            top: -32vh;
        }

        .stagePlayer:nth-of-type(1) {
            left: 25%;
        }

        .stagePlayer:nth-of-type(2) {
            right: 40%;
        }
    }

    &.variation-2 {
        width: 60%;

        .stagePlayer {
            top: -32vh;
        }

        .stagePlayer:nth-of-type(1) {
            left: 10%;

            .potato {
                top: 23vh;
            }
        }

        .stagePlayer:nth-of-type(2) {
            left: 40%;

            .potato {
                top: 20.5vh;
            }
        }

        .stagePlayer:nth-of-type(3) {
            right: 28%;
        }
    }

    &.variation-3 {
        width: 75%;

        .stagePlayer {
            top: -32vh;
        }

        .stagePlayer:nth-of-type(1) {
            left: 7.5%;

            .potato {
                top: 23.5vh;
            }
        }

        .stagePlayer:nth-of-type(2) {
            left: 32%;

            .potato {
                top: 20.5vh;
            }
        }

        .stagePlayer:nth-of-type(3) {
            right: 45%;

            .potato {
                top: 20.5vh;
            }
        }

        .stagePlayer:nth-of-type(4) {
            right: 22%;

            .potato {
                top: 21vh;
            }
        }
    }

    &.variation-4 {
        &.front {
            width: 75%;
            bottom: -15vh;

            .stagePlayer {
                top: -32vh;
            }

            .stagePlayer:nth-of-type(1) {
                left: 10%;

                .potato {
                    top: 23vh;
                }
            }

            .stagePlayer:nth-of-type(2) {
                left: 42%;

                .potato {
                    top: 20.5vh;
                }
            }

            .stagePlayer:nth-of-type(3) {
                right: 25%;

                .potato {
                    top: 22vh;
                }
            }
        }


        &.back {
            width: 60%;
            bottom: 17vh;
            z-index: -1;

            .stagePlayer {
                top: -32vh;
            }

            .stagePlayer:nth-of-type(1) {
                left: 20%;

                .potato {
                    top: 22vh;
                }
            }

            .stagePlayer:nth-of-type(2) {
                right: 37.5%;

                .potato {
                    top: 21vh;
                }
            }
        }
    }

    &.variation-5 {
        &.front {
            width: 75%;
            bottom: -15vh;

            .stagePlayer {
                top: -32vh;
            }

            .stagePlayer:nth-of-type(1) {
                left: 17%;

                .potato {
                    top: 21.5vh;
                }
            }

            .stagePlayer:nth-of-type(2) {
                left: 43.75%;

                .potato {
                    top: 20.5vh;
                }
            }

            .stagePlayer:nth-of-type(3) {
                right: 29%;

                .potato {
                    top: 21vh;
                }
            }
        }


        &.back {
            width: 60%;
            bottom: 17vh;
            z-index: -1;

            .stagePlayer {
                top: -32vh;
            }


            .stagePlayer:nth-of-type(1) {
                left: 10%;

                .potato {
                    top: 23vh;
                }
            }

            .stagePlayer:nth-of-type(2) {
                left: 42%;

                .potato {
                    top: 20.5vh;
                }
            }

            .stagePlayer:nth-of-type(3) {
                right: 25%;

                .potato {
                    top: 21vh;
                }
            }
        }
    }

    &.variation-6 {
        &.front {
            width: 75%;
            bottom: -15vh;

            .stagePlayer {
                top: -32vh;
            }

            .stagePlayer:nth-of-type(1) {
                left: 5%;

                .potato {
                    top: 23vh;
                }
            }

            .stagePlayer:nth-of-type(2) {
                left: 30%;

                .potato {
                    top: 21vh;
                }
            }

            .stagePlayer:nth-of-type(3) {
                right: 43%;

                .potato {
                    top: 20.5vh;
                }
            }

            .stagePlayer:nth-of-type(4) {
                right: 18%;

                .potato {
                    top: 22vh;
                }
            }
        }


        &.back {
            width: 60%;
            bottom: 17vh;
            z-index: -1;

            .stagePlayer {
                top: -32vh;
            }


            .stagePlayer:nth-of-type(1) {
                left: 10%;

                .potato {
                    top: 23vh;
                }
            }

            .stagePlayer:nth-of-type(2) {
                left: 42%;

                .potato {
                    top: 20.5vh;
                }
            }

            .stagePlayer:nth-of-type(3) {
                right: 25%;

                .potato {
                    top: 21vh;
                }
            }
        }
    }
}
